<template>
  <svg height="64" width="64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="currentColor">
    <path
      d="M36.46034,36.39854c-.28243.3727-.61461.68795-.99729.94496-.75161.50315-1.65476.75473-2.70641.75473-1.59506,0-2.91076-.57226-3.94786-1.716-1.03862-1.14452-1.55679-2.61593-1.55679-4.41657,0-1.83092.51128-3.3101,1.53306-4.43909,1.02179-1.12821,2.34667-1.69348,3.97081-1.69348,1.08226,0,2.0298.29739,2.84187.89216.05434.0396.10486.08308.15767.12501.0176.01398.03904.0264.05588.04038.01378.01087.02449.02329.03827.03417.05205.04348.10103.08929.15001.13511.78146.71357,1.22003,1.51877,1.46036,2.06386l5.71819-1.91866c-.72252-2.28826-2.01526-4.09666-3.87974-5.42365-1.86524-1.32699-4.04583-1.99087-6.54098-1.99087-2.2265,0-4.24865.51946-6.06797,1.55604-1.82085,1.03737-3.2483,2.47927-4.28692,4.32494-1.0371,1.84645-1.55679,3.9437-1.55679,6.2933s.52735,4.44763,1.58052,6.2933c1.05164,1.84645,2.48751,3.28836,4.30759,4.32494,1.81932,1.03659,3.82693,1.55605,6.02435,1.55605,2.4056,0,4.50276-.61807,6.29299-1.85343,1.78871-1.23615,3.08986-2.95215,3.90193-5.14878l-5.68911-1.9241c-.18982.36105-.44852.77491-.80366,1.18566h.00001Z"
    />
    <path
      d="M14.07422,58.96235c-.36182,0-.72314-.09863-1.04248-.29297-.59277-.3623-.95508-1.00586-.95752-1.7002l-.01709-5.39844c-4.03418-.9209-7.05615-4.5752-7.05615-8.92871v-21.23535c0-5.04639,4.06006-9.15234,9.05029-9.15234h35.89795c4.99023,0,9.05078,4.10596,9.05078,9.15234v21.23535c0,5.04688-4.06055,9.15234-9.05078,9.15234h-21.32129l-13.646,6.9502c-.28564.14551-.59717.21777-.90771.21777ZM14.05127,16.25434c-2.78467,0-5.05029,2.31152-5.05029,5.15234v21.23535c0,2.84082,2.26562,5.15234,5.05029,5.15234,1.10205,0,1.99658.8916,2,1.99316l.0127,3.91699,11.17627-5.69238c.28125-.14355.59229-.21777.90771-.21777h21.80127c2.78516,0,5.05078-2.31152,5.05078-5.15234v-21.23535c0-2.84082-2.26562-5.15234-5.05078-5.15234H14.05127Z"
    />
  </svg>
</template>
